










































































































import Vue from 'vue';
import { mapMutations } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import axios from 'axios';
import RepositoryFactory from '@/services/RepositoryFactory';
import IShipmentProviderConfig, {
  IProvider,
  IShipmentConfig,
} from '@/services/Repositories/shipmentProviderConfigs/types';
import dpdRepositories from '@/services/DpdRepositories/getRepositories/getRepositories';
import DPDErrorHandler from '@/services/DpdRepositories/DPDErrorHandler';

const Factory = new RepositoryFactory();

export default Vue.extend({
  name: 'ShipmentProvider',
  data() {
    return {
      productionEnv: false,
      provider: {} as IProvider,
      popup: false,
      showPassword: false,
      config: {
        userCredentialsCloudUserId: '',
        userCredentialsToken: '',
        boxShippingShipService: '',
      },
      showChangeCredentialsForm: false,
      boxShippingShipServices: ['Classic_Predict', 'Express_18'],
      providers: [],
      environment: [],
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BasePopup: () => import('@/components/ui/BasePopup.vue'),
    TheFabButton: () => import('@/components/ui/TheFabButton.vue'),
  },
  computed: {
    shipmentRepository(): IShipmentProviderConfig {
      return Factory.get('shipmentConfig') as IShipmentProviderConfig;
    },
    shipmentProvider(): string {
      const { providerId } = this.$route.params;
      return providerId;
    },
    envLabel(): string {
      return this.productionEnv ? 'live' : 'sandbox';
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_PAGE']),
    async getProvider(): Promise<void> {
      const { data } = await this.shipmentRepository.getOne(this.shipmentProvider);
      const { envType } = data;
      this.productionEnv = envType !== 'sandbox';
      this.provider = data;
    },
    updatedForm(): void {
      if (this.provider.dpdRestConfig) {
        const {
          dpdRestConfig: { userCredentialsCloudUserId, boxShippingShipService },
        } = this.provider;
        this.config.boxShippingShipService = boxShippingShipService;
        this.config.userCredentialsCloudUserId = userCredentialsCloudUserId || '';
      }
    },
    initView(): void {
      const { icons, actions } = this.$headerService.getIconsAndActions();
      this.$headerService.config({
        title: this.provider.name,
        icons: { icon: icons.back, action: actions.goBack },
        page: { name: 'ShippingProvider' },
      });
      this.SET_PAGE('ShippingProvider');
    },
    async updateProvider(): Promise<void> {
      const { name, interfaceType, provider } = this.provider;
      const config: IShipmentConfig = {
        name,
        interfaceType,
        provider,
        envType: this.productionEnv ? 'live' : 'sandbox',
        dpdRestConfig: {
          userCredentialsCloudUserId: this.config.userCredentialsCloudUserId,
          userCredentialsToken: this.config.userCredentialsToken,
          boxShippingShipService: this.config.boxShippingShipService,
        },
      };

      if (!this.showChangeCredentialsForm) {
        config.dpdRestConfig = {
          boxShippingShipService: this.config.boxShippingShipService,
        };

        await this.shipmentRepository.update(this.shipmentProvider, config);
        this.popup = true;
        return;
      }

      const zipCodeData = await dpdRepositories.getZipCode(this.config, this.productionEnv);

      if (zipCodeData.ErrorDataList) {
        const [first] = zipCodeData.ErrorDataList;
        DPDErrorHandler.handler(first);
        return;
      }

      await this.shipmentRepository.update(this.shipmentProvider, config);
      this.popup = true;
    },
    goToProviders() {
      this.$router.replace({ name: 'ShippingProvider' });
    },
    radioLabelsAction(): void {
      this.productionEnv = !this.productionEnv;
      if (!this.showChangeCredentialsForm) {
        this.showChangeCredentialsForm = true;
      }
    },
    async getEnums() {
      const apiURL = process.env.VUE_APP_BASE_URL;
      const data = await axios.get(`${apiURL}docs.json`);
      console.log(data.data.components.schemas);
      this.environment = data.data.components.schemas['ShipmentProviderConfig-shipment_provider_config_context.read'].properties.envType.enum;
      this.providers = data.data.components.schemas['ShipmentProviderConfig-shipment_provider_config_context.read'].properties.provider.enum;
    },
  },
  async created() {
    await this.getProvider();
    await this.getEnums();
    this.updatedForm();
    this.initView();
    this.SET_PAGE('ShippingProvider');
  },
  watch: {
    config: {
      handler(newVal) {
        if (this.provider.dpdRestConfig) {
          const {
            dpdRestConfig: { '@id': id, '@type': type, ...rest },
          } = this.provider;
          if (JSON.stringify(rest) !== JSON.stringify(newVal)) {
            const { icons, actions } = this.$headerService.getIconsAndActions();
            this.$headerService.setHeaderIcons({ icon: icons.cancel, action: actions.close });
          }
        }
      },
      deep: true,
    },
  },
});
