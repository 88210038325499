var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height"},[_c('v-row',{staticClass:"fill-height"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0 pointer",on:{"click":_vm.radioLabelsAction}},[_vm._v(_vm._s(_vm.$t('sandbox')))]),_c('v-switch',{staticClass:"ml-5 mr-1",attrs:{"value":_vm.productionEnv,"inset":""},on:{"change":_vm.radioLabelsAction,"click":function () { return (_vm.showChangeCredentialsForm = true); }}}),_c('p',{staticClass:"mb-0 pointer",on:{"click":_vm.radioLabelsAction}},[_vm._v(_vm._s(_vm.$t('live')))])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"fill-height d-flex flex-column justify-space-between",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateProvider)}}},[_c('v-container',{staticClass:"pa-0"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('shipment.name'),"error-messages":errors,"outlined":""},model:{value:(_vm.provider.name),callback:function ($$v) {_vm.$set(_vm.provider, "name", $$v)},expression:"provider.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.providers,"label":_vm.$t('shipment.providers'),"error-messages":errors,"outlined":""},model:{value:(_vm.provider.provider),callback:function ($$v) {_vm.$set(_vm.provider, "provider", $$v)},expression:"provider.provider"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.boxShippingShipServices,"label":_vm.$t('shipment.service'),"error-messages":errors,"outlined":""},model:{value:(_vm.config.boxShippingShipService),callback:function ($$v) {_vm.$set(_vm.config, "boxShippingShipService", $$v)},expression:"config.boxShippingShipService"}})]}}],null,true)}),_c('v-container',{staticClass:"d-flex justify-center mb-7"},[_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":function($event){_vm.showChangeCredentialsForm = !_vm.showChangeCredentialsForm}}},[_vm._v(" "+_vm._s(_vm.$t('inputs.update-credentials'))+" ")])],1),(_vm.showChangeCredentialsForm)?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.showChangeCredentialsForm)?_c('v-text-field',{attrs:{"label":_vm.$t('inputs.cloud-user-token'),"error-messages":errors,"outlined":""},model:{value:(_vm.config.userCredentialsCloudUserId),callback:function ($$v) {_vm.$set(_vm.config, "userCredentialsCloudUserId", $$v)},expression:"config.userCredentialsCloudUserId"}}):_vm._e()]}}],null,true)}):_vm._e(),(_vm.showChangeCredentialsForm)?_c('ValidationProvider',{ref:"token",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.showChangeCredentialsForm)?_c('v-text-field',{attrs:{"label":_vm.$t('inputs.token'),"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? '$eye' : '$eyeOff',"error-messages":errors,"outlined":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.config.userCredentialsToken),callback:function ($$v) {_vm.$set(_vm.config, "userCredentialsToken", $$v)},expression:"config.userCredentialsToken"}}):_vm._e()]}}],null,true)}):_vm._e()],1),_c('TheFabButton',{attrs:{"type":"submit","title":'Update',"disabled":invalid}})],1)]}}])})],1)],1),_c('BasePopup',{attrs:{"popupIsOpen":_vm.popup},scopedSlots:_vm._u([{key:"popup-title",fn:function(){return [_c('h3',[_vm._v(_vm._s(_vm.$t('popup.shipping-provider')))])]},proxy:true},{key:"popup-body",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.$t('popup.shipping-provider-update')))])]},proxy:true},{key:"popup-actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.goToProviders}},[_vm._v(_vm._s(_vm.$t('inputs.confirm')))])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }